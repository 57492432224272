import { SEAMSTRESS_LINK, PREPRODUCER_LINK, QA_LINK } from '../router/links';

export const ACCESS_TOKEN_KEY = 'accessToken';

export const constLink = {
    QA: QA_LINK,
    Producer: SEAMSTRESS_LINK,
    Preproducer: PREPRODUCER_LINK,
};

export const statusList = {
    PREPARING: 'Preparing',
    PREPARED: 'Prepared',
    QAPASSED: 'QAPassed',
};

export const errorListAuth = {
    'Not Found': 'Не удалось загрузить пользователей',
    Unauthorized: 'Введен неверный пароль',
    UserBlocked: 'Пользователь заблокирован',
    'An error occurred while processing your request.': 'Неверный адрес',
};
