import React from 'react';
import { Accordion, Button, Icon, Input } from 'semantic-ui-react';

function TaskList({ form, setForm, activeItem, onChange, onSubmit, complete }) {
    return (
        <Accordion fluid styled>
            {form.operations.map((item, index) => (
                <div>
                    <Accordion.Title
                        className="task__title"
                        active={form.activeItem === index}
                        index={index}
                        onClick={activeItem}
                    >
                        <div className="task__headerTask">
                            <div className="task__block">
                                <p>{item.operationName}</p>
                                <div>
                                    <p className="task__headerSubtitle">
                                        Модель: {item.modelName} /
                                        Цвет: {item.modelColor} /
                                        Размер: {item.modelSize}
                                    </p>
                                </div>
                            </div>
                            <span>{item.producedCount + ' / ' + item.requiredCount}</span>
                        </div>
                    </Accordion.Title>
                    <Accordion.Content className="task__text" active={form.activeItem === index}>
                        <div className="task__bodySea">
                            <div>
                                <span className="task__name">{item.operationName}</span>
                            </div>
                            <div className="task__buttons">
                                <span className="task__cost">
                                    {item.cost} <Icon name="rub" />
                                </span>
                                <div className="task__buttonOne">
                                    {form.activeNumber ? (
                                        <Input
                                            id={item.requiredCount}
                                            placeholder="0"
                                            labelPosition="left corner"
                                            className="task__input"
                                            action
                                            value={form.updateOperations.producedCount}
                                            onChange={onChange}
                                        >
                                            <Button
                                                id={item.requiredCount}
                                                name="decrement"
                                                onClick={onChange}
                                                value={-1}
                                                basic
                                                className="task__number"
                                                compact
                                            >
                                                <Icon name="minus" />
                                            </Button>
                                            <input />
                                            <Button
                                                id={item.requiredCount}
                                                name="increment"
                                                value={1}
                                                onClick={onChange}
                                                basic
                                                className="task__number"
                                                compact
                                            >
                                                <Icon name="plus" />
                                            </Button>
                                        </Input>
                                    ) : (
                                        <Button
                                            onClick={() => complete(item)}
                                            icon="check circle"
                                            basic
                                            color="green"
                                            className="task__button"
                                            content="Готово"
                                        />
                                    )}
                                </div>
                                <div className="task__buttonTwo">
                                    {form.activeNumber ? (
                                        <Button
                                            secondary
                                            className="task__button"
                                            content="Ок"
                                            onClick={onSubmit}
                                        />
                                    ) : (
                                        <Button
                                            secondary
                                            className="task__button"
                                            content="Частично"
                                            onClick={() =>
                                                setForm(
                                                    Object.assign({}, form, {
                                                        activeNumber: true,
                                                        updateOperations: { ...item },
                                                    }),
                                                )
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <span className="task__costMubile">
                                {item.cost} <Icon name="rub" />
                            </span>
                        </div>
                        <div className="task__buttonsMobile">
                            <div className="task__buttonOne">
                                {form.activeNumber ? (
                                    <Input
                                        id={item.requiredCount}
                                        placeholder="0"
                                        labelPosition="left corner"
                                        className="task__input"
                                        action
                                        value={form.updateOperations.producedCount}
                                        onChange={onChange}
                                    >
                                        <Button
                                            id={item.requiredCount}
                                            name="decrement"
                                            onClick={onChange}
                                            value={-1}
                                            basic
                                            className="task__number"
                                            compact
                                        >
                                            <Icon name="minus" />
                                        </Button>
                                        <input />
                                        <Button
                                            id={item.requiredCount}
                                            name="increment"
                                            value={1}
                                            onClick={onChange}
                                            basic
                                            className="task__number"
                                            compact
                                        >
                                            <Icon name="plus" />
                                        </Button>
                                    </Input>
                                ) : (
                                    <Button
                                        onClick={() => complete(item)}
                                        icon="check circle"
                                        basic
                                        color="green"
                                        className="task__button"
                                        content="Готово"
                                    />
                                )}
                            </div>
                            <div className="task__buttonTwo">
                                {form.activeNumber ? (
                                    <Button
                                        secondary
                                        className="task__button"
                                        content="Ок"
                                        onClick={onSubmit}
                                    />
                                ) : (
                                    <Button
                                        secondary
                                        className="task__button"
                                        content="Частично"
                                        onClick={() =>
                                            setForm(
                                                Object.assign({}, form, {
                                                    activeNumber: true,
                                                    updateOperations: { ...item },
                                                }),
                                            )
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    </Accordion.Content>
                </div>
            ))}
        </Accordion>
    );
}

export default TaskList;
