import { all } from 'redux-saga/effects';

import { watchUser } from '../ducks/user';
import { watchTask } from '../ducks/task';
import { watchPosition } from '../ducks/position';
import { watchReport } from '../ducks/report';

export function* rootWatcher() {
    yield all([watchUser(), watchTask(), watchPosition(), watchReport()]);
}
