import React from 'react';
import { Icon } from 'semantic-ui-react';

import './style.scss';

function Notice({ form, time, setTitle }) {
    setTimeout(() => {
        if (form) {
            setTitle(null);
        }
    }, time);

    return (
        <>
            {form && (
                <div className="notice">
                    <div className="notice__body">
                        <h3 className="notice__title">
                            <Icon name="check circle outline" size="large" /> {form}
                        </h3>
                    </div>
                </div>
            )}
        </>
    );
}

export default Notice;
