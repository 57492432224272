export function setOptions(arr) {
    return arr.map((value) => {
        return {
            id: value.id,
            value: value.id,
            text: value.name,
        };
    });
}

export function ls(key, value) {
    let _value = value;

    if (typeof value === 'object') {
        _value = JSON.stringify(_value);
    }

    if (arguments.length === 2) {
        localStorage.setItem(key, _value);
    } else {
        return localStorage.getItem(key);
    }
}

export function getName() {
    const url = window.location.href;

    return url.split('.')[0].split('/')[2];
}

export function getPositionCostOperation(duration, costPerSecond, count) {
    const totalSeconds = duration * count;

    return (costPerSecond * totalSeconds) / 100;
}
