import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { ls } from '../utils/functionHelper';
import { ACCESS_TOKEN_KEY } from '../utils/const';

function PrivateRoute({ component: Component }) {
    if (ls(ACCESS_TOKEN_KEY)) {
        return <Route render={() => <Component />} />;
    }
    return (
        <Redirect
            to={{
                pathname: '/',
            }}
        />
    );
}

export default PrivateRoute;
