import { put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { postman } from '../utils/postman';

//*                                                       /Operations                                              *//

//*  TYPES  *//

export const GET_TASK_LIST_REQUEST = 'GET_TASK_LIST_REQUEST';
export const GET_TASK_LIST_SUCCESS = 'GET_TASK_LIST_SUCCESS';

export const UPDATE_TASK_REQUEST = 'UPDATE_TASK_REQUEST';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';

//*  INITIAL STATE  *//

const initial = {
    task: [],
};

//*  REDUCER  *//

export default function task(state = initial, action) {
    switch (action.type) {
        case GET_TASK_LIST_SUCCESS:
            return {
                ...state,
                task: action.payload,
            };
        case UPDATE_TASK_SUCCESS:
            return {
                ...state,
                task: action.payload,
            };
        default:
            return state;
    }
}

//*  ACTION CREATORS  *//

export const getTask = () => {
    return {
        type: GET_TASK_LIST_REQUEST,
    };
};

export const updateTask = (payload) => {
    return {
        type: UPDATE_TASK_REQUEST,
        payload,
    };
};

//*  SELECTORS *//

export const baseUserState = (state) => state.task;

export const taskList = createSelector(
    [baseUserState],
    (data) =>
        data.task &&
        data.task.map((item) => {
            return {
                ...item,
                id: item.id,
                operationName: item.operationName,
                cost: item.cost,
                producedCount: 0,
                requiredCount: Number(item.requiredCount) - Number(item.producedCount),
            };
        }),
);

//*  SAGA  *//

function* workerGetTask() {
    try {
        const response = yield postman.get(`/Operations`);

        const data = response.map(function(p){ return p.operations; })
            .reduce(function(a, b){ return a.concat(b); }, [])

        yield put({
            type: GET_TASK_LIST_SUCCESS,
            payload: data,
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerUpdateTask({ payload }) {
    try {
        yield postman.put(`/Operations/${payload.id}`, { producedCount: payload.producedCount });

        const responseTask = yield postman.get(`/Operations`);

        const data = responseTask.map(function(p){ return p.operations; })
            .reduce(function(a, b){ return a.concat(b); }, [])

        yield put({
            type: UPDATE_TASK_SUCCESS,
            payload: data,
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

export function* watchTask() {
    yield takeEvery(GET_TASK_LIST_REQUEST, workerGetTask);
    yield takeEvery(UPDATE_TASK_REQUEST, workerUpdateTask);
}
