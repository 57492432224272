import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { positionList, getPosition, editPosition } from '../../ducks/position';
import PositionList from './components/positionList';
import { ACCESS_TOKEN_KEY, statusList } from '../../utils/const';
import { Notice } from '../../components';

import './style.scss';

function QaPage() {
    const task = useSelector(positionList);
    const [form, setForm] = useState({ active: false });
    const [notification, setNotification] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const activeItem = (event, { index }) => {
        setForm(Object.assign({}, form, { active: index }));
        setNotification(null)
    };

    const backToLogin = () => {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        history.push('/');
    };

    const editStatus = (id) => {
        dispatch(
            editPosition({
                data: {
                    id,
                    status: statusList.QAPASSED,
                },
                callbackSuccess: fetchData,
            }),
        );
        setForm(Object.assign({}, form, { active: false }));
        setNotification('Позиция завершена')
    };

    function fetchData() {
        dispatch(getPosition());
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="qa">
            <div className="qa__body">
                <header className="qa__header">
                    <span className="qa__exit" onClick={backToLogin}>
                        <Icon name="angle left" />
                        Выйти
                    </span>
                    {form.active !== false && (
                        <span
                            className="qa__exit"
                            onClick={() => setForm(Object.assign({}, form, { active: false }))}
                        >
                            <Icon name="times circle outline" />
                            Отменить дейсвие
                        </span>
                    )}
                </header>
                <section className="task">
                    <PositionList
                        active={form.active}
                        task={task}
                        activeItem={activeItem}
                        editStatus={editStatus}
                    />
                </section>
                <Notice form={notification} setTitle={setNotification} time={2000} />
            </div>
        </div>
    );
}

export default QaPage;
