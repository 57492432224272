import React from 'react';
import { Accordion, Button, Grid } from 'semantic-ui-react';
import {format, parseISO} from "date-fns";

function PositionList({ active, activeItem, editStatus, task }) {
    return (
        <Accordion fluid styled>
            {task.map((item, index) => (
                <>
                    <Accordion.Title
                        className="task__title"
                        active={active === index}
                        index={index}
                        onClick={activeItem}
                    >
                        <p>{`Номер заказа: ${item.order.number}`}</p>
                        <p>{`Название модели: ${item.model.name}`}</p>
                        <p>{`Заказчик: ${item.order.customerName}`}</p>
                    </Accordion.Title>
                    <Accordion.Content className="task__text" active={active === index}>
                        <div className='task__body'>
                            <span>Размер: {item.size.name}</span>
                            <span>Цвет: {item.color.name}</span>
                            <span>Колличество: {item.count}</span>
                            <span>Дата: {format(parseISO(item.order.date), 'dd.MM.yyyy',)}</span>
                            <Button
                                onClick={() => editStatus(item.id)}
                                icon="check circle"
                                basic
                                color="green"
                                className="task__button"
                                content="Готово"
                            />
                        </div>
                        <Button
                            onClick={() => editStatus(item.id)}
                            icon="check circle"
                            basic
                            color="green"
                            className="task__button-mibile"
                            content="Готово"
                        />
                    </Accordion.Content>
                </>
            ))}
        </Accordion>
    );
}

export default PositionList;
