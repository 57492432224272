import { put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { postman } from '../utils/postman';

//*                                                       /Report                                              *//

//*  TYPES  *//

export const GET_INCOME_REQUEST = 'GET_INCOME_REQUEST';
export const GET_INCOME_SUCCESS = 'GET_INCOME_SUCCESS';


//*  INITIAL STATE  *//

const initial = {
    income: [],
};

//*  REDUCER  *//

export default function report(state = initial, action) {
    switch (action.type) {
        case GET_INCOME_SUCCESS:
            return {
                ...state,
                income: action.payload,
            };
        default:
            return state;
    }
}

//*  ACTION CREATORS  *//

export const getIncomeRequest = () => {
    return {
        type: GET_INCOME_REQUEST,
    };
};


//*  SELECTORS *//

export const baseReportState = (state) => state.report;

export const getIncome = createSelector([baseReportState], (state) => state.income);

//*  SAGA  *//

function* workerGetIncome() {
    try {
        const response = yield postman.get(`/Report/Income`);

        yield put({
            type: GET_INCOME_SUCCESS,
            payload: response,
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

export function* watchReport() {
    yield takeEvery(GET_INCOME_REQUEST, workerGetIncome);
}
