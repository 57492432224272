import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Icon, Input, Grid, Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import className from 'classnames';

import { getUserList, userAll, authorization, userError, clareError } from '../../ducks/user';
import { constLink, errorListAuth } from '../../utils/const';
import { getName } from '../../utils/functionHelper';

import './style.scss';

function AuthorizationPersonal() {
    const dispatch = useDispatch();
    const user = useSelector(userAll);
    const error = useSelector(userError);
    const history = useHistory();
    const [form, setForm] = useState({
        login: null,
        password: '',
    });
    const name = getName();
    const onChange = (event, { name, value }) => {
        setForm(Object.assign({}, form, { [name]: value }));
    };
    const onClickNumber = (event, { name, value }) => {
        let newValue;

        if (value === 'delete') {
            newValue = form.password.slice(0, -1);
        } else {
            newValue = form.password + value;
        }

        setForm(Object.assign({}, form, { [name]: newValue }));
    };
    const onClickLogin = () => {
        if (form.login) {
            setForm(Object.assign({}, form, { login: null, password: '' }));
            dispatch(clareError());
        } else {
            window.location.href = `https://${name}${process.env.REACT_APP_URL}`;
        }
    };
    function userName() {
        const userValue = user.filter((i) => i.id === form.login)[0];
        return userValue.text.split(' ')[1]
            ? userValue.text.split(' ')[1]
            : userValue.text.split(' ')[0];
    }

    useEffect(() => {
        dispatch(getUserList());
        dispatch(clareError());
    }, []);

    useEffect(() => {
        if (form.password.length > 3) {
            dispatch(
                authorization({
                    data: { loginId: form.login, password: form.password },
                    callbackSuccess: (role) => history.push({ pathname: constLink[role] }),
                }),
            );
        }
    }, [form]);

    const onSubmit = () => {
        dispatch(
            authorization({
                data: { loginId: form.login, password: form.password },
                callbackSuccess: (role) => history.push({ pathname: constLink[role] }),
            }),
        );
    };

    return (
        <div className="authorization-personal">
            <header className="header">
                <div className="header__body">
                    <div className="header__nav">
                        <span onClick={onClickLogin}>
                            <Icon name="angle left" />
                            Назад
                        </span>
                    </div>
                    <h1 className="header__title">Авторизация сотрудника</h1>
                    <div className="header__nav">
                        {form.password && (
                            <span onClick={onSubmit}>
                                Далее
                                <Icon name="angle right" />
                            </span>
                        )}
                    </div>
                </div>
            </header>
            <section className="form">
                {
                    error && <div
                        className={className({
                            form__error: error,
                        })}
                    >
                        <div className='form__error-body'>
                            <p>{error && errorListAuth[error]}</p>
                        </div>
                    </div>
                }
                <div className="form__body">
                    {!form.login && (
                        <div className='form__input-block'><Dropdown
                            trigger={
                                <span>
                                    <Icon name="user" />
                                    Выберите фамилию из списка
                                </span>
                            }
                            name="login"
                            value={form.login}
                            className="form__input"
                            fluid
                            selection
                            options={user}
                            onChange={onChange}
                        /></div>
                    )}
                    {form.login && (
                        <>
                            <p className="form__text">{`${userName()} введите пин-код`}</p>
                            <Input
                                type="password"
                                placeholder="XXXX"
                                labelPosition="left corner"
                                className="form__password"
                                name="password"
                                value={form.password}
                                onChange={onChange}
                                autoFocus
                            />
                            <Grid className="form__grid">
                                <Grid.Row>
                                    <Grid.Column width={5}>
                                        <Button
                                            className="form__num"
                                            name="password"
                                            value={1}
                                            content="1"
                                            onClick={onClickNumber}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        <Button
                                            className="form__num"
                                            name="password"
                                            onClick={onClickNumber}
                                            value={2}
                                            content="2"
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        <Button
                                            name="password"
                                            className="form__num"
                                            onClick={onClickNumber}
                                            content="3"
                                            value={3}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={5}>
                                        <Button
                                            name="password"
                                            className="form__num"
                                            onClick={onClickNumber}
                                            content="4"
                                            value={4}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        <Button
                                            name="password"
                                            value={5}
                                            className="form__num"
                                            onClick={onClickNumber}
                                            content="5"
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        <Button
                                            name="password"
                                            value={6}
                                            className="form__num"
                                            onClick={onClickNumber}
                                            content="6"
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={5}>
                                        <Button
                                            name="password"
                                            value={7}
                                            className="form__num"
                                            onClick={onClickNumber}
                                            content="7"
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        <Button
                                            name="password"
                                            value={8}
                                            className="form__num"
                                            onClick={onClickNumber}
                                            content="8"
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        <Button
                                            name="password"
                                            value={9}
                                            className="form__num"
                                            onClick={onClickNumber}
                                            content="9"
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={5} />
                                    <Grid.Column width={5}>
                                        <Button
                                            name="password"
                                            value={0}
                                            onClick={onClickNumber}
                                            className="form__num"
                                            content="0"
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        <Button
                                            name="password"
                                            value="delete"
                                            onClick={onClickNumber}
                                            className="form__num"
                                            icon="arrow left"
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </>
                    )}
                </div>
            </section>
        </div>
    );
}

export default AuthorizationPersonal;
