import { put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { postman } from '../utils/postman';

//*                                                       /Position                                              *//

//*  TYPES  *//

export const GET_POSITION_LIST_REQUEST = 'GET_POSITION_LIST_REQUEST';
export const GET_POSITION_LIST_SUCCESS = 'GET_POSITION_LIST_SUCCESS';

export const EDIT_POSITION_REQUEST = 'EDIT_POSITION_REQUEST';
export const EDIT_POSITION_SUCCESS = 'EDIT_POSITION_SUCCESS';

//*  INITIAL STATE  *//

const initial = {
    position: [],
};

//*  REDUCER  *//

export default function position(state = initial, action) {
    switch (action.type) {
        case GET_POSITION_LIST_SUCCESS:
            return {
                ...state,
                position: action.payload,
            };
        default:
            return state;
    }
}

//*  ACTION CREATORS  *//

export const getPosition = () => {
    return {
        type: GET_POSITION_LIST_REQUEST,
    };
};

export const editPosition = (payload) => {
    return {
        type: EDIT_POSITION_REQUEST,
        payload,
    };
};

//*  SELECTORS *//

export const baseUserState = (state) => state.position;

export const positionList = createSelector([baseUserState], (state) => state.position);

//*  SAGA  *//

function* workerGetPosition() {
    try {
        const response = yield postman.get(`/Positions`);

        yield put({
            type: GET_POSITION_LIST_SUCCESS,
            payload: response,
        });
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

function* workerEditPosition({ payload }) {
    try {
        const { data, callbackSuccess } = payload;

        yield postman.put(`/Positions/${data.id}`, { status: data.status });

        callbackSuccess();
    } catch (err) {
        const { data } = err;

        alert(data.title);
    }
}

export function* watchPosition() {
    yield takeEvery(GET_POSITION_LIST_REQUEST, workerGetPosition);
    yield takeEvery(EDIT_POSITION_REQUEST, workerEditPosition);
}
