import { combineReducers } from 'redux';

import user from '../ducks/user';
import task from '../ducks/task';
import position from '../ducks/position';
import report from '../ducks/report';

export default combineReducers({
    user,
    task,
    position,
    report
});
