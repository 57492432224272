import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { SEAMSTRESS_LINK, LOGIN_TERMINAL_LINK, QA_LINK, PREPRODUCER_LINK } from './links';
import PrivateRoute from './privateRoute';
import { Seamstress, AuthorizationPersonal, Qa, Preproducer } from '../pages';

function MainRoute() {
    return (
        <Switch>
            {/*<Route path={LOGIN_TERMINAL_LINK} component={AuthorizationTerminal} />*/}
            <Route exact path={'/'} component={AuthorizationPersonal} />
            <PrivateRoute exact path={SEAMSTRESS_LINK} component={Seamstress} />
            <PrivateRoute exact path={QA_LINK} component={Qa} />
            <PrivateRoute exact path={PREPRODUCER_LINK} component={Preproducer} />
        </Switch>
    );
}

export default MainRoute;
