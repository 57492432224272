import { put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { postman, setAccessToken } from '../utils/postman';
import { ls, setOptions } from '../utils/functionHelper';
import { ACCESS_TOKEN_KEY } from '../utils/const';

//*                                                       /Auth                                              *//

//*  TYPES  *//

export const GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';

export const AUTHORIZATION_USER_REQUEST = 'AUTHORIZATION_USER_REQUEST';
export const AUTHORIZATION_USER_SUCCESS = 'AUTHORIZATION_USER_SUCCESS';

export const ERROR_HANDLER_USER = 'ERROR_HANDLER_USER';

export const CLARE_ERROR = 'CLARE_ERROR';

//*  INITIAL STATE  *//

const initial = {
    user: [],
    error: null,
    isAuth: false,
};

//*  REDUCER  *//

export default function user(state = initial, action) {
    switch (action.type) {
        case GET_USER_LIST_SUCCESS:
            return {
                ...state,
                user: action.payload ? action.payload : [],
            };
        case ERROR_HANDLER_USER:
            return {
                ...state,
                error: action.payload,
            };
        case AUTHORIZATION_USER_SUCCESS:
            return {
                ...state,
                isAuth: true,
            };
        case CLARE_ERROR:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

//*  ACTION CREATORS  *//

export const getUserList = (payload) => {
    return {
        type: GET_USER_LIST_REQUEST,
        payload,
    };
};

export const authorization = (payload) => {
    return {
        type: AUTHORIZATION_USER_REQUEST,
        payload,
    };
};

export const clareError = () => {
    return {
        type: CLARE_ERROR,
    };
};

//*  SELECTORS *//

export const baseUserState = (state) => state.user;

export const userAll = createSelector(
    [baseUserState],
    (data) => data.user && setOptions(data.user),
);
export const userError = createSelector([baseUserState], (state) => state.error);

//*  SAGA  *//

function* workerAuthorization({ payload }) {
    try {
        const { callbackSuccess, data } = payload;
        const response = yield postman.post(`/Auth/Login`, { ...data });

        ls(ACCESS_TOKEN_KEY, response.token);
        setAccessToken(response.token);
        callbackSuccess(response.role);
        yield put({
            type: AUTHORIZATION_USER_SUCCESS,
        });
    } catch (err) {
        const { data } = err;

        yield put({
            type: ERROR_HANDLER_USER,
            payload: data.title,
        });
    }
}

function* workerGetUser() {
    try {
        const response = yield postman.get(`/Auth/Users`);

        yield put({
            type: GET_USER_LIST_SUCCESS,
            payload: response,
        });
    } catch (err) {
        const { data } = err;

        yield put({
            type: ERROR_HANDLER_USER,
            payload: data.title,
        });
    }
}

export function* watchUser() {
    yield takeEvery(AUTHORIZATION_USER_REQUEST, workerAuthorization);
    yield takeEvery(GET_USER_LIST_REQUEST, workerGetUser);
}
