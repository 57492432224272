import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { taskList, getTask, updateTask } from '../../ducks/task';
import TaskList from './components/taskList';
import { ACCESS_TOKEN_KEY } from '../../utils/const';
import { getIncomeRequest, getIncome } from '../../ducks/report';
import { Notice } from '../../components';

import './style.scss';

function Seamstress() {
    const task = useSelector(taskList);
    const report = useSelector(getIncome);
    const [form, setForm] = useState({
        activeItem: false,
        activeNumber: false,
        operations: [],
        updateOperations: {},
    });

    const [notification, setNotification] = useState(null);

    const history = useHistory();
    const dispatch = useDispatch();

    const activeItem = (event, { index }) => {
        setForm(
            Object.assign({}, form, {
                activeItem: form.activeItem === index ? false : index,
                activeNumber: false,
                updateOperations: {},
            }),
        );
        setNotification(null);
    };

    const onChange = (event, { value, name, id }) => {
        const newUpdateOperations = form.updateOperations;

        if (name === 'decrement') {
            newUpdateOperations.producedCount =
                newUpdateOperations.producedCount > 0 ? --newUpdateOperations.producedCount : 0;
        } else if (name === 'increment') {
            newUpdateOperations.producedCount =
                newUpdateOperations.producedCount === id ? id : ++newUpdateOperations.producedCount;
        } else {
            newUpdateOperations.producedCount = +value > 0 ? (+value > id ? +id : +value) : '';
        }

        setForm(Object.assign({}, form, { updateOperations: newUpdateOperations }));
    };

    const backToLogin = () => {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        history.push('/');
    };

    const complete = (item) => {
        onSubmit({ ...item, producedCount: item.requiredCount });
    };

    const onSubmit = (tempItem) => {
        dispatch(updateTask(tempItem.id ? tempItem : form.updateOperations));
        setForm(Object.assign({}, form, { activeItem: false, activeNumber: false }));
        setNotification('Данные сохранены');
    };

    useEffect(() => {
        setForm(Object.assign({}, form, { operations: [...task] }));
    }, [task]);

    useEffect(() => {
        dispatch(getTask());
    }, []);

    useEffect(() => {
        dispatch(getIncomeRequest());
    }, [task]);

    return (
        <div className="seamstress">
            <div className="seamstress__body">
                <header className="seamstress__header">
                    <span className="seamstress__exit" onClick={backToLogin}>
                        <Icon name="angle left" />
                        Выйти
                    </span>
                    {form.activeNumber !== false && (
                        <span
                            className="seamstress__exit"
                            onClick={() =>
                                setForm(
                                    Object.assign({}, form, {
                                        activeNumber: false,
                                        updateOperations: {},
                                    }),
                                )
                            }
                        >
                            <Icon name="times circle outline" />
                            Отменить дейсвие
                        </span>
                    )}
                </header>
                <section className="task">
                    <TaskList
                        form={form}
                        setForm={setForm}
                        activeItem={activeItem}
                        onChange={onChange}
                        onSubmit={onSubmit}
                        complete={complete}
                    />
                    <div className="salary">
                        <p className="salary__text">
                            Заработано сегодня: {report.dailyIncome} <Icon name="rub" />
                        </p>
                        <p className="salary__text">
                            За месяц: {report.monthlyIncome} <Icon name="rub" />
                        </p>
                    </div>
                </section>
                <Notice form={notification} setTitle={setNotification} time={2000} />
            </div>
        </div>
    );
}

export default Seamstress;
